.ProjectSettings__title {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.ProjectSettings__titleName {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.ProjectSettings__content {
  padding: 16px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModelConfiguring__frames {
  padding: 16px 48px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  height: 478px;
}

.ModelConfiguring__tableFrame {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.ModelConfiguring__frameTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
}

.ModelConfiguring__tableFrameHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModelConfiguring__contentLeft {
  display: flex;
  align-items: flex-end;
}
.ProjectSettings__shortInfo {
  display: flex;
}

.ProjectSettings__shortInfoItem {
  margin-right: 56px;
}

.ProjectSettings__units {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__kineticLimits {
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverFirstRow,
.ProjectSettings__inhibitionAndToxicity {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverSecondRow {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 48px;
  margin-top: 40px;
}

.ProjectSettings__inputParameter {
  max-width: 350px;
}

.ProjectSettings__heading {
  margin-top: 48px;
  margin-bottom: 12px;
}

.ProjectSettings__row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__actionButtons {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 16px;
  margin-top: 32px;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.ProjectSettings__actionButtonAdvanced {
  cursor: pointer;
  &:hover {
    background: #ffed00;
  }
}
.Radio__title,
.MaxMinField__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  margin-bottom: 12px;
}

.Radio__option {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  margin: 4px 0;
  min-height: 33px;
}
.Radio__checkWrapper {
  background: #ffffff;
  border: 2px solid #606060;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 10px;
}

.Radio__check {
  background: #606060;
  border-radius: 100%;
  //padding: 2px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}

.Radio__input {
  width: 60px;
}

.RadioWithInputs {
  .Radio__option {
    display: grid;
    grid-template-columns: auto 1fr 60px;
  }
  .Radio__optionPlaceholder {
    min-width: 30px;
  }
}
.MaxMinField__items {
  display: flex;
  align-items: center;
}

.MaxMinField__input {
  width: 60px;
}

.MaxMinField__item {
  margin-right: 40px;
}

.MaxMinField__itemPlaceholder {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #606060;
  margin-top: 4px;
}

.ModelConfiguring__saveDate {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 12px;

  color: #606060;
}
.ModelConfiguring__saveDateIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.ModelConfiguring__table {
  border: 1px solid #606060;
  overflow-x: auto;
  max-height: calc(100vh - 300px);
  //max-width: calc(100vw - 420px);
  scroll-behavior: smooth;
  user-select: none;
  //width: fit-content;
  margin-bottom: 20px;
  min-width: 100%;
  height: 100px;
}

.ModelConfiguring__placeholder {
  padding: 4px;
  padding-top: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #606060;
}
.ModelConfiguring__placeholderText {
  margin-bottom: 8px;
}
.ModelConfiguring__tableName {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
}
.ModelConfiguring__tableSelect {
  border: none;
  background-color: transparent;
  font-size: 11px;
  line-height: 15px;

  color: #000000;
  margin-top: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    border: none;
    outline: none;
  }
}

.ModelConfiguring__tableTitle {
  display: flex;
  flex-flow: column nowrap;
}
.ModelConfiguring__tableScrollIcon {
  z-index: 10;
  background: #ffed00;
  color: #000000;
  width: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50% - 60px / 2);
  right: 0;
  position: absolute;
  cursor: pointer;
  &:hover {
    background: #000000;
    color: #ffed00;
  }
}
.Checkboxes {
  display: flex;
  align-items: center;
}

.Checkboxes__item {
  font-size: 12px;
  line-height: 16px;

  color: #303030;
  display: flex;
  align-items: center;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.Checkboxes__checkedIcon,
.Checkboxes__removeIcon {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.Checkboxes__checkedIcon {
  color: #ffed00;
}

.Fitting__recalculateWrapper {
  margin: 16px 48px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Fitting__recalculateLabel {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
}

.Fitting__recalculateSelectWrapper {
  position: relative;
  &:after {
    position: absolute;
    right: 12px;
    top: 17px;
    width: 14px;
    height: 14px;

    content: '';
    background: url('./assets/select-arrow.svg') no-repeat;
  }
}
.Fitting__recalculateSelect {
  background: #f5f5f5;
  border: 1.5px solid #606060;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  height: 40px;
  padding: 0 36px 0 12px;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    border: 1.5px solid #606060;
    outline: none;
  }
}

.Info__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
  margin-bottom: 4px;
}

.Info__content {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  padding: 0 20px;
}

.Info__item {
  display: flex;
  flex-flow: column nowrap;
  //align-items: center;
  padding: 4px 0;
  &:last-child {
    border-bottom: none;
  }
}

.Info__itemName {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;

  color: #000000;
}

.Info__itemValue {
  display: flex;
  align-items: center;
}

.Info__itemUnit {
  margin-left: 8px;
  white-space: nowrap;
}

.Fitting__infos {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-gap: 36px;
}

.Fitting__measuredVsPredictedFit {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.Fitting__variablesGraphics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.Fitting__variableName {
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;

  color: #606060;
  margin-top: -48px;
  margin-bottom: 40px;
}

.Fitting__variablesGraphicsSelect {
  display: flex;
}

.CustomizedTooltip {
  background: #606060;
  padding: 14px 10px;
  font-size: 12px;
  line-height: 14px;

  /* Sartorius White */

  color: #ffffff;
  position: relative;
}

.CustomizedTooltip__close {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.CustomizedTooltip__field {
  font-weight: 300;
}

.CustomizedTooltip__fieldValue {
  font-weight: bold;
}

.CustomizedTooltip__buttons {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-gap: 8px;
  margin-top: 8px;
}

.CustomizedTooltip__button {
  padding: 4px 8px;
  border: 1px solid #000000;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.stages {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 48px;
}
.stageWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stage {
  background: #fff;
  border: 1px solid #bdbdbd;
  padding: 12px;
  display: flex;
  flex-direction: column;
  //overflow: hidden;
}

.stageTitle {
  font-size: 36px;
  line-height: 49px;
}

.stageBlocks {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stageButtons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.stageContent {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 36px;
  //overflow: hidden;
}

.stage :global(.MultiSelect__name),
.stage :global(.MultiSelect__name) input {
  min-width: unset;
  width: unset;
}

.stage :global(.MultiSelect__name) {
  width: 222px;
  height: 30px;
}

.graphics {
  height: 400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  overflow: auto;
}

.emptyStages {
  display: flex;
  justify-content: center;
}

.buttons {
  display: flex;align-items: center;gap: 4px;
}

.loading svg{
  animation: loading 1s infinite linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
