.MultiSelect {
  position: relative;
  &:hover {
    .MultiSelect__error {
      display: flex;
    }
  }
}

.MultiSelect__error {
  bottom: unset;
  display: none;
  align-items: center;
  background: #C10202;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 8px 16px;
  margin-top: 10px;
  z-index: 10;
  width: 100%;
  position: absolute;
}

.MultiSelect__name {
  background: #f5f5f5;
  border: 1.5px solid #606060;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  input {
    padding: 12px 12px;
    margin: 0;
    border: none;
    width: 300px;
    background: #f5f5f5;
    &:focus {
      outline: none;
    }
  }
  svg {
    max-width: 16px;
    max-height: 16px;
    margin: 0 4px;
  }
}

.MultiSelect__name_error {
  border-color: red;
}

.MultiSelect__name_disabled {
  pointer-events: none;
}

.MultiSelect__options {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  background: #e5e5e5;
  border: 1px solid #606060;
  //overflow: hidden;
  z-index: 10;
  max-height: 200px;
  overflow: auto;
}

.MultiSelect__options_open {
  visibility: visible;
}

.MultiSelect__option {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  cursor: pointer;
  padding: 16px 12px;
  &:hover {
    background-color: rgba(0, 0,0,0.1);
  }
}

.MultiSelect__option_selected {
  font-weight: bold;
}
