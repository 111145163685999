.ProjectCard {
  cursor: pointer;
  width: 320px;
  margin-right: 24px;
  margin-bottom: 24px;
  position: relative;
  &:not(.ProjectCard_disabled):hover {
    .ProjectCard__head {
      background: #ffed00;
    }
  }
}

.ProjectCard_disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.ProjectCard__head {
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 152px;
  position: relative;
}

.ProjectCard__content {
  background: #F5F5F5;
  padding: 20px 30px;
}
.ProjectCard__dropdown {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  &:hover {
    .ProjectCard__dropdownContent {
      opacity: 1;
    }
  }
}

.ProjectCard__dropdownIcon {
  color: white;
}

.ProjectCard__dropdownContent {
  opacity: 0;
  position: absolute;
  right: -2px;
  top: 25px;
  background: #606060;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 14px;
//min-width: 88px;
font-weight: 300;
  /* Sartorius White */

  color: #FFFFFF;
  & div {
    white-space: nowrap;
  }
}

.ProjectCard__dropdownItem {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
}

.ProjectCard__titleOnHover {
  opacity: 0;
  position: absolute;
  top: 210px;
  left: 40px;
  width: 260px;
  background-color: #F5F5F5;
  overflow: hidden;
  word-break: break-all;
  white-space: pre-wrap;
  border: 1.5px solid #606060;
  padding: 4px;
  border-radius: 16px;
  transition: opacity 0.3s ease-in-out;
}
.ProjectCard__title {
  height: 32px;
  color: #000000;
  width: 260px;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover .ProjectCard__titleOnHover {
    opacity: 1;
  }
}

.ProjectCard__titleContent {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #000000;
  margin-bottom: 16px;
}

.ProjectCard__owner {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 4px;
}

.ProjectCard__infoBox {
  display: flex;
  gap: 20px;
}

.ProjectCard__ownerContent {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 19px;
}

.ProjectCard__updated {
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
}

.Modal__content {
  display: flex;
  flex-flow: column nowrap;align-items: center;
}

.Modal__actionText {

  display: block;
  margin: 20px 0;
  font-weight: 600;
  text-align: center;
  max-width: 350px;
  font-size: 24px;
  line-height: 33px;

  color: #000000;
}

.Modal__buttons {
  display: flex;
  margin: 20px 0;
  & button:first-child {
    margin-right: 20px;
  }
}
